/**
 *
 * RestrictedPage
 *
 */
import * as React from 'react'
import styled from 'styled-components/macro'
// Components
import { PageLayout } from 'app/components/PageLayout'
import { P } from 'app/components/NotFoundPage/P'

export function RestrictedPage() {
  return (
    <PageLayout pageTitle="Restricted" loading={false} icon="mdi-home">
      <div className="row home">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <Wrapper>
                <Title>
                  4
                  <span role="img" aria-label="Crying Face">
                    😢
                  </span>
                  3
                </Title>
                <P>You don't have a permission to access the page.</P>
              </Wrapper>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
`

const Title = styled.div`
  margin-top: -8vh;
  font-weight: bold;
  color: black;
  font-size: 3.375rem;

  span {
    font-size: 3.125rem;
  }
`
