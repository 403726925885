/**
 *
 * PageLayout
 *
 */
import * as React from 'react'
// External
import LoadingOverlay from 'react-loading-overlay-ts'
LoadingOverlay.propTypes = undefined

interface Props {
  children?: React.ReactNode
  loading?: boolean
  pageTitle?: string
  icon?: string
}

export function PageLayout(props: Props) {
  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          <span className="page-title-icon bg-primary text-white mr-2">
            <i className={`mdi ${props.icon}`}></i>
          </span>
          {props.pageTitle}
        </h3>
      </div>
      <LoadingOverlay active={props.loading || false} spinner>
        {props.children}
      </LoadingOverlay>
    </div>
  )
}
