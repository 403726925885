/**
 *
 * AppraisalStatusUpdate
 *
 */
import * as React from 'react'
// React bootstrap
import { Modal, Button } from 'react-bootstrap'
// External
import { useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-bootstrap-tagsinput/dist/index.css'

interface Props {
  visible: boolean
  handleClose: () => void
  appraisal: any
  handleSubmit: (data: any) => void
  role: string
  suppliers: any
}

export function AppraisalStatusUpdate(props: Props) {
  const { register, handleSubmit } = useForm()

  const [form, setForm] = React.useState<any>({ date: new Date() })
  const [status, setStatus] = React.useState<string>('')
  const [supplier, setSupplier] = React.useState<string>('')

  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value)
  }

  const handleChangeSupplier = (event: any) => {
    setSupplier(event.target.value)
  }

  const handleDateChange = (name: string, date) => {
    setForm({
      ...form,
      [name]: date,
    })
  }

  const onSubmit = (data: any) => {
    if (status && status === 'P.O Issued' && !form.date) {
      alert('Select po date')
      return
    }
    if (status && status === 'P.O Issued' && !supplier) {
      alert('Select supplier')
      return
    }
    const payload = props.appraisal
    payload.status = data.status
    payload.remarks = data.remarks || ''
    if (data.status === 'P.O Issued') {
      const min = 10000
      const max = 99999
      const unique_number = Math.floor(Math.random() * (max - min + 1)) + min
      const selectedSupplier = props?.suppliers?.find(
        (supp: any) => supp?.id === parseInt(supplier),
      )
      payload.date = form.date
      payload.supplier = selectedSupplier?.name
      payload.supplier_id = selectedSupplier?.id
      payload.po_number = unique_number
      payload.address = data.address
      payload.payment_terms = data.payment_terms
      payload.quantity = data.quantity
      payload.description = data.description
      payload.unit_price = data.unit_price
      payload.prepared_by = data.prepared_by
      payload.approved_by = data.approved_by
    }
    props.handleSubmit(payload)
  }

  const today = new Date()
  const startOfToday = new Date(today.setHours(0, 0, 0, 0))
  const endOfToday = new Date(today.setHours(23, 59, 59, 999))
  return (
    <Modal show={props.visible} onHide={props.handleClose}>
      <Modal.Header>
        <Modal.Title>Appraisal status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label>Status</label>
                <select
                  className="form-control form-control-lg"
                  {...register('status', { required: true })}
                  onChange={handleChangeStatus}
                >
                  <option value="" />
                  {props.appraisal && props.appraisal.status === 'New' && (
                    <option value="Void">Void</option>
                  )}
                  {props.role &&
                    (props.role.includes('super_admin') ||
                      props.role.includes('manager')) &&
                    props.appraisal &&
                    props.appraisal.initial_appraised_value > 0 &&
                    props.appraisal.status === 'For Approval' && (
                      <option value="Approved">Approved</option>
                    )}
                  {props.role &&
                    (props.role.includes('super_admin') ||
                      props.role.includes('manager')) &&
                    props.appraisal &&
                    props.appraisal.initial_appraised_value > 0 &&
                    props.appraisal.status === 'For Approval' && (
                      <option value="Declined">Declined</option>
                    )}
                  {props.appraisal &&
                    props.appraisal.status === 'Approved' &&
                    props.appraisal.with_inspection && (
                      <option value="Owner Accepted">Owner Accepted</option>
                    )}
                  {props.appraisal &&
                    props.appraisal.status === 'Approved' &&
                    props.appraisal.with_inspection && (
                      <option value="Owner Rejected">Owner Rejected</option>
                    )}
                  {props.appraisal &&
                    props.appraisal.status === 'Approved' &&
                    props.appraisal.with_inspection && (
                      <option value="Cancelled">Cancelled</option>
                    )}
                  {props.appraisal?.with_inspection &&
                    props.appraisal?.status === 'Owner Accepted' && (
                      <option value="P.O Issued">P.O Issued</option>
                    )}
                  {props.appraisal &&
                    props.appraisal.status === 'Owner Rejected' && (
                      <option value="Owner Accepted">Owner Accepted</option>
                    )}
                </select>
              </div>
              {status && status === 'P.O Issued' && (
                <>
                  <div className="form-group">
                    <label>Date</label>
                    <DatePicker
                      selected={form?.date ? new Date(form.date) : new Date()}
                      onChange={date => handleDateChange('date', date)}
                      minDate={startOfToday}
                      maxDate={endOfToday}
                      className="form-control form-control-lg"
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label>Supplier</label>
                    <select
                      className="form-control form-control-lg"
                      {...register('supplier', { required: true })}
                      onChange={handleChangeSupplier}
                    >
                      <option value="" />
                      {props?.suppliers?.map(supplier => (
                        <option key={supplier?.id} value={supplier?.id}>
                          {supplier?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      className="form-control form-control-lg"
                      {...register('address', { required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <label>Terms of payment</label>
                    <input
                      className="form-control form-control-lg"
                      {...register('payment_terms', { required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <label>Quantity</label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      {...register('quantity', { required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <input
                      className="form-control form-control-lg"
                      {...register('description', { required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <label>Unit price</label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      {...register('unit_price', { required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <label>Prepared by:</label>
                    <input
                      className="form-control form-control-lg"
                      {...register('prepared_by', { required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <label>Approved by:</label>
                    <input
                      className="form-control form-control-lg"
                      {...register('approved_by', { required: true })}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Remarks</label>
                <textarea
                  className="form-control form-control-lg"
                  id="exampleFormControlTextarea1"
                  rows={3}
                  {...register('remarks', { required: false })}
                ></textarea>
              </div>
            </div>
          </div>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Update
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  )
}
