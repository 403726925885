/**
 *
 * Asynchronously loads the component for FeedbacksPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const FeedbacksPage = lazyLoad(
  () => import('./index'),
  module => module.FeedbacksPage,
)
