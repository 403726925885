/**
 *
 * Asynchronously loads the component for LeadPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const LeadPage = lazyLoad(
  () => import('./index'),
  module => module.LeadPage,
)
