/**
 *
 * AppointmentsPage
 *
 */
import React, { memo } from 'react'
// Components
import { PageLayout } from 'app/components/PageLayout'
// External
import BootstrapTable from 'react-bootstrap-table-next'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
// Utils
import { config } from 'utils/config'
import { getLocalStorage } from 'utils/tokenStorage'

interface Props {}

export const AppointmentsPage = memo((props: Props) => {
  const [bookings, setBookings] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [selectedDate, setSelectedDate] = React.useState<any>(new Date())

  React.useEffect(() => {
    fetchBookings('')
  }, [])

  const fetchBookings = async (params: String) => {
    try {
      setLoading(true)
      const { data } = await config.apiClient.get(
        `/appointments/bookings?${params}`,
        {
          headers: { Authorization: 'Bearer ' + getLocalStorage().token },
        },
      )
      setBookings([...data.data.bookings, ...data.data.general])
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleDateChange = (date: Date) => {
    setSelectedDate(date)
    fetchBookings('booking_date=' + moment(date).format('YYYY-MM-DD'))
  }

  const noDataIndication = () => {
    return <h5 className="text-center">No booking/s on this date</h5>
  }

  const columns = [
    {
      dataField: 'reference_no',
      text: 'Refno',
    },
    {
      dataField: 'booking_time',
      text: 'Scheduled time',
      headerClasses: 'id-custom-width',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cellContent, row) => {
        let statusColor = ''
        if (row.status === 'Pending' || row.status === 'Inquiry') {
          statusColor = 'status-red'
        }
        if (row.status === 'Warm Lead') {
          statusColor = 'status-yellow'
        }
        if (row.status === 'Hot Lead') {
          statusColor = 'status-green'
        }
        return (
          <p
            className={`font-weight-bold mb-0 ${statusColor}`}
          >{`${row.status}`}</p>
        )
      },
    },
    {
      dataField: 'type',
      text: 'Type',
    },
    {
      dataField: 'lastname',
      text: 'Client',
      formatter: (cellContent, row: any) => {
        return <p>{`${row.firstname} ${row.lastname}`}</p>
      },
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Vehicle/s',
      formatter: (cellContent, row: any) => {
        if (row.vehicles && row.vehicles.length > 0) {
          return (
            <>
              {row.vehicles.map((vehicle: any) => (
                <p className="mb-0">
                  {vehicle.make +
                    ' ' +
                    vehicle.model +
                    ' ' +
                    vehicle.year +
                    ' with ' +
                    vehicle.plate_no}
                </p>
              ))}
            </>
          )
        }
        return `${row.make} ${row.model}  ${row.year}`
      },
    },
    {
      dataField: 'df2',
      isDummyField: true,
      text: 'Agent',
      formatter: (cellContent, row: any) => {
        if (row.agent) {
          return `${row.agent.firstname} ${row.agent.lastname}`
        }
        return ''
      },
    },
  ]

  const expandRow = {
    showExpandColumn: true,
    renderer: (row: any) => (
      <>
        <div className="row">
          <div className="col col-lg-6">
            <div className="list-group">
              <a
                href="#"
                className="list-group-item list-group-item-action flex-column align-items-start active"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">Client</h5>
                </div>
                <p className="mb-0">{row.firstname + ' ' + row.lastname}</p>
                <p className="mb-0">{row.email}</p>
                <p className="mb-0">{row.phone}</p>
              </a>
              <a
                href="#"
                className="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">Vehicles</h5>
                </div>
                {row.vehicles && row.vehicles.length > 0 ? (
                  <>
                    {row.vehicles.map((vehicle: any) => (
                      <p className="mb-0">
                        {vehicle.make +
                          ' ' +
                          vehicle.model +
                          ' ' +
                          vehicle.year +
                          ' with ' +
                          vehicle.plate_no}
                      </p>
                    ))}
                  </>
                ) : (
                  <p className="mb-0">
                    {row.make + ' ' + row.model + ' ' + row.year}
                  </p>
                )}
              </a>
              <a
                href="#"
                className="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">Schedule</h5>
                </div>
                <p className="mb-0">
                  {row.booking_date + ' at ' + row.booking_time}
                </p>
              </a>
              <a
                href="#"
                className="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">Details</h5>
                </div>
                <p className="mb-0">Comments: {row.comments}</p>
                <p className="mb-0">Remarks: {row.remarks}</p>
                <p className="mb-0">Type: {row.type}</p>
                <p className="mb-0">Location: {row.location}</p>
                <p className="mb-0">Referral code: {row.referral_code}</p>
                <p className="mb-0">Source: {row.source}</p>
                <p className="mb-0">Client: {row.client_id}</p>
                <p className="mb-0">ClientIp: {row.ip_address}</p>
                <p className="mb-0">
                  Visited: {row.visited_carmax ? 'Yes' : 'No'}
                </p>
              </a>
              <a
                href="#"
                className="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">Agent</h5>
                </div>
                {row.agent_id === 0 ? (
                  <p className="mb-0">Temporary Reserved</p>
                ) : (
                  <p className="mb-0">
                    {row.agent &&
                      row.agent.firstname + ' ' + row.agent.lastname}
                  </p>
                )}
              </a>
            </div>
          </div>
        </div>
      </>
    ),
  }

  return (
    <PageLayout
      pageTitle="Appointments today"
      loading={loading}
      icon="mdi-calendar"
    >
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>Filter by date</label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      className="form-control form-control-lg"
                      minDate={new Date()}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p>
                    Date: {`${moment(selectedDate).format('MMMM Do YYYY')}`}
                  </p>
                </div>
              </div>
              <div className="table-responsive">
                <BootstrapTable
                  remote
                  keyField="id"
                  data={bookings}
                  columns={columns}
                  noDataIndication={noDataIndication}
                  expandRow={expandRow}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
})
