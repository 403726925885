/**
 *
 * Asynchronously loads the component for FinancingsPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const FinancingsPage = lazyLoad(
  () => import('./index'),
  module => module.FinancingsPage,
)
