/**
 *
 * VehicleAppraisal
 *
 */
import * as React from 'react'
// React bootstrap
import { Modal, Button } from 'react-bootstrap'

interface Props {
  visible: boolean
  prediction: any
  handleClose: () => void
}

export function VehicleAppraisal(props: Props) {
  const peso = new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
  })
  const predictedValue = peso.format(props?.prediction?.predicted_value || 0)
  const marketValue = peso.format(props?.prediction?.market_value || 0)
  const askingPrice = peso.format(props?.prediction?.asking_price || 0)
  return (
    <Modal show={props.visible} onHide={props.handleClose}>
      <Modal.Body>
        <div className="pt-3">
          <h3 className="mb-0">{predictedValue}</h3>
          <p>Predicted value</p>
        </div>
        <div>
          <h3 className="mb-0">{marketValue}</h3>
          <p>Market value</p>
        </div>
        <div>
          <h3 className="mb-0">{askingPrice}</h3>
          <p>Asking price</p>
        </div>
        <div>
          <h3 className="mb-0">{props?.prediction?.projected_gp}</h3>
          <p>Grojected gp</p>
        </div>
        {/* <div>
          <h3 className="mb-0">{props?.prediction?.avg_days_between}</h3>
          <p>Avg days between similar listings</p>
        </div> */}
        <div className="border-bottom border-3" />
        <div>
          <h3 className="mb-0">{props?.prediction?.gp_score}</h3>
          <p>GP score</p>
        </div>
        <div>
          <h3 className="mb-0">{props?.prediction?.demand_score}</h3>
          <p>Demand score</p>
        </div>
        <div>
          <h3 className="mb-0">{props?.prediction?.overall_score}</h3>
          <p>Overall score</p>
        </div>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Body>
    </Modal>
  )
}
