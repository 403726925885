/**
 *
 * Footer
 *
 */
import * as React from 'react'

interface Props {}

export function Footer(props: Props) {
  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between py-2">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
          Copyright © 2022 Carmax PH
        </span>
        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          Developed by Lica Ventures Dev Team.
        </span>
      </div>
    </footer>
  )
}
