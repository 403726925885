/**
 *
 * Asynchronously loads the component for WaitlistsPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const WaitlistsPage = lazyLoad(
  () => import('./index'),
  module => module.WaitlistsPage,
)
