/**
 *
 * Asynchronously loads the component for BookingLeadsPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const BookingLeadsPage = lazyLoad(
  () => import('./index'),
  module => module.BookingLeadsPage,
)
