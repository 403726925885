/**
 *
 * Asynchronously loads the component for VehicleInspectionPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const VehicleInspectionPage = lazyLoad(
  () => import('./index'),
  module => module.VehicleInspectionPage,
)
