/**
 *
 * Sidebar
 *
 */
import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Collapse } from 'react-bootstrap'

interface Props {}

export function Sidebar(props: Props) {
  const location = useLocation()
  const [state, setState] = React.useState({
    vehiclesMenu: false,
    bookingsMenu: false,
    articlesMenu: false,
    financingsMenu: false,
    leadsMenu: false,
    appraisalsMenu: false,
  })

  const toggleMenuState = (menuState: string) => {
    if (state[menuState]) {
      setState({
        ...state,
        [menuState]: false,
      })
    } else if (Object.keys(state).length === 0) {
      setState({
        ...state,
        [menuState]: true,
      })
    } else {
      Object.keys(state).forEach(i => {
        setState({
          ...state,
          [i]: false,
        })
      })
      setState({
        ...state,
        [menuState]: true,
      })
    }
  }

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li
          className={location.pathname === '/' ? 'nav-item active' : 'nav-item'}
        >
          <Link className="nav-link" to="/">
            <span className="menu-title">Dashboard</span>
            <i className="mdi mdi-home menu-icon"></i>
          </Link>
        </li>
        <li
          className={
            location.pathname === '/inquiries' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/inquiries">
            <span className="menu-title">Inquiries</span>
            <i className="mdi mdi-heart menu-icon"></i>
          </Link>
        </li>
        <li
          className={
            location.pathname === '/quotations' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/quotations">
            <span className="menu-title">Quotations</span>
            <i className="mdi mdi-cash-check menu-icon"></i>
          </Link>
        </li>
        <li
          className={
            location.pathname === '/offers' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/offers">
            <span className="menu-title">Offers</span>
            <i className="mdi mdi-swap-horizontal menu-icon"></i>
          </Link>
        </li>
        <li
          className={
            location.pathname === '/bookings' ||
            location.pathname === '/generalbookings'
              ? 'nav-item active'
              : 'nav-item'
          }
        >
          <div
            className={'nav-link'}
            onClick={() => toggleMenuState('bookingsMenu')}
            data-toggle="collapse"
          >
            <span className="menu-title">Test Drives</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-book-check menu-icon"></i>
          </div>
          <Collapse in={state?.bookingsMenu}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className={'nav-link'} to="/bookings">
                  Bookings
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link'} to="/appointments">
                  Appointments
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link'} to="/bookings/leads">
                  Actual test drives
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link'} to="/generalbookings">
                  General (Deprecated)
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li
          className={
            location.pathname === '/financings' ? 'nav-item active' : 'nav-item'
          }
        >
          <div
            className={'nav-link'}
            onClick={() => toggleMenuState('financingsMenu')}
            data-toggle="collapse"
          >
            <span className="menu-title">Financings</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-currency-usd menu-icon"></i>
          </div>
          <Collapse in={state?.financingsMenu}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className={'nav-link'} to="/financings">
                  Pre-Qualifications
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link'} to="/financings/applications">
                  Financing Applications
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li
          className={
            location.pathname === '/reservations'
              ? 'nav-item active'
              : 'nav-item'
          }
        >
          <Link className="nav-link" to="/reservations">
            <span className="menu-title">Reservations</span>
            <i className="mdi mdi-swap-horizontal menu-icon"></i>
          </Link>
        </li>
        <li
          className={
            location.pathname === '/vehicles' ? 'nav-item active' : 'nav-item'
          }
        >
          <div
            className={'nav-link'}
            onClick={() => toggleMenuState('vehiclesMenu')}
            data-toggle="collapse"
          >
            <span className="menu-title">Vehicles</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-car-side menu-icon"></i>
          </div>
          <Collapse in={state?.vehiclesMenu}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className={'nav-link'} to="/vehicles">
                  All
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li
          className={
            location.pathname === '/collections'
              ? 'nav-item active'
              : 'nav-item'
          }
        >
          <Link className="nav-link" to="/collections">
            <span className="menu-title">Collections</span>
            <i className="mdi mdi-swap-horizontal menu-icon"></i>
          </Link>
        </li>
        <li
          className={
            location.pathname === '/appraisals' ? 'nav-item active' : 'nav-item'
          }
        >
          <div
            className={'nav-link'}
            onClick={() => toggleMenuState('appraisalsMenu')}
            data-toggle="collapse"
          >
            <span className="menu-title">Appraisals</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-currency-usd menu-icon"></i>
          </div>
          <Collapse in={state?.appraisalsMenu}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className={'nav-link'} to="/appraisals">
                  Requests
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link'} to="/vehicles-market-value">
                  Market value
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link'} to="/suppliers">
                  Suppliers
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li
          className={
            location.pathname === '/waitlists' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/waitlists">
            <span className="menu-title">Vehicles request</span>
            <i className="mdi mdi-chart-box-outline menu-icon"></i>
          </Link>
        </li>
        <li
          className={
            location.pathname === '/feedbacks' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/feedbacks">
            <span className="menu-title">Feedbacks</span>
            <i className="mdi mdi-comment menu-icon"></i>
          </Link>
        </li>
        <li
          className={
            location.pathname === '/users' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/users">
            <span className="menu-title">Users</span>
            <i className="mdi mdi-account-multiple-outline menu-icon"></i>
          </Link>
        </li>
        <li
          className={
            location.pathname === '/leads' ? 'nav-item active' : 'nav-item'
          }
        >
          <div
            className={'nav-link'}
            onClick={() => toggleMenuState('leadsMenu')}
            data-toggle="collapse"
          >
            <span className="menu-title">Leads Tracker</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-file-excel menu-icon"></i>
          </div>
          <Collapse in={state?.leadsMenu}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className={'nav-link'} to="/leads">
                  All leads
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link'} to="/leads/new">
                  Add new
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li
          className={
            location.pathname === '/banners' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/banners">
            <span className="menu-title">Web Banners</span>
            <i className="mdi mdi-format-header-1 menu-icon"></i>
          </Link>
        </li>
      </ul>
    </nav>
  )
}
