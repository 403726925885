/**
 *
 * Asynchronously loads the component for FinancingPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const FinancingPage = lazyLoad(
  () => import('./index'),
  module => module.FinancingPage,
)
