/**
 *
 * Asynchronously loads the component for ArticlesPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const ArticlesPage = lazyLoad(
  () => import('./index'),
  module => module.ArticlesPage,
)
