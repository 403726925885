/**
 *
 * Asynchronously loads the component for VehiclesPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const VehiclesPage = lazyLoad(
  () => import('./index'),
  module => module.VehiclesPage,
)
