/**
 *
 * Asynchronously loads the component for VehiclePage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const VehiclePage = lazyLoad(
  () => import('./index'),
  module => module.VehiclePage,
)
