/**
 *
 * Asynchronously loads the component for BannersPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const BannersPage = lazyLoad(
  () => import('./index'),
  module => module.BannersPage,
)
