/**
 *
 * Asynchronously loads the component for RemarksPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const RemarksPage = lazyLoad(
  () => import('./index'),
  module => module.RemarksPage,
)
