/**
 *
 * VehicleMarketValuesPage
 *
 */
import React, { memo } from 'react'
// Components
import { PageLayout } from 'app/components/PageLayout'

interface Props {}

export const VehicleMarketValuesPage = memo((props: Props) => {
  return (
    <PageLayout
      pageTitle="Vehicles market value"
      loading={false}
      icon="mdi-cash-check"
    >
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <iframe
                src="https://carmaxappraisal-k2jujxfytfwqbdnaa7fu4d.streamlit.app/?embedded=true"
                width="100%"
                height="1005"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
})
