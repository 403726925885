/**
 *
 * Header
 *
 */
import * as React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
// Utils
import { removeLocalStorage } from 'utils/tokenStorage'
import { Auth } from 'utils/auth'

interface Props {}

export function Header(props: Props) {
  const User = {
    id: Auth.user()?.user_id,
    firstname: Auth.user()?.firstname,
    lastname: Auth.user()?.lastname,
    role: Auth.user()?.role,
  }

  const navigate = useNavigate()

  const handleLogout = () => {
    removeLocalStorage()
    navigate('/login')
  }

  const toggleOffCanvas = () => {
    const sidebar = document.querySelector('.sidebar-offcanvas')
    if (sidebar) {
      sidebar.classList.toggle('active')
    }
  }
  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/">
          <img
            src="https://carmax.com.ph/_next/image?url=%2Fcarmax-logo.webp&w=256&q=40"
            alt="CarmaxPH"
          />
        </Link>
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img src="/carmax_square.png" alt="carmax_square.png" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle('sidebar-icon-only')}
        >
          <span className="mdi mdi-menu"></span>
        </button>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile">
            <Dropdown>
              <Dropdown.Toggle className="d-none d-md-block nav-link desktop mx-0">
                <div className="nav-profile-text">
                  <p className="mb-1 text-black text-capitalize">
                    {User?.firstname || ''} {User?.lastname || ''}
                  </p>
                </div>
              </Dropdown.Toggle>
              {/* <Dropdown.Toggle className="d-none d-md-none d-lg-none nav-link mobile">
                <div className='nav-profile-img'>
                  <img src='https://i.pravatar.cc/50' />
                </div>
              </Dropdown.Toggle> */}
            </Dropdown>
          </li>
          <li className="nav-item nav-logout d-none d-lg-block">
            <a className="nav-link" href="#/" onClick={handleLogout}>
              <i className="mdi mdi-power"></i>
            </a>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffCanvas}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  )
}
