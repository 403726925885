/**
 *
 * Asynchronously loads the component for AppraisalInspectionPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const AppraisalInspectionPage = lazyLoad(
  () => import('./index'),
  module => module.AppraisalInspectionPage,
)
