/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import {
  useLocation,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import { GlobalStyle } from 'styles/global-styles'
import './App.scss'

import { LoginPage } from './pages/LoginPage/Loadable'
import { HomePage } from './pages/HomePage/Loadable'
import { FeedbacksPage } from './pages/FeedbacksPage/Loadable'
import { VehiclesPage } from './pages/VehiclesPage/Loadable'
import { CollectionsPage } from './pages/CollectionsPage/Loadable'
import { VehiclePage } from './pages/VehiclePage/Loadable'
import { VehicleInspectionPage } from './pages/VehicleInspectionPage/Loadable'
import { BookingsPage } from './pages/BookingsPage/Loadable'
import { GeneralBookingsPage } from './pages/GeneralBookingsPage/Loadable'
import { FinancingsPage } from './pages/FinancingsPage/Loadable'
import { FinancingPage } from './pages/FinancingPage/Loadable'
import { FinancingApplicationPage } from './pages/FinancingApplicationPage/Loadable'
import { ReservationsPage } from './pages/ReservationsPage/Loadable'
import { UsersPage } from './pages/UsersPage/Loadable'
import { BannersPage } from './pages/BannersPage/Loadable'
import { ArticlesPage } from './pages/ArticlesPage/Loadable'
import { WaitlistsPage } from './pages/WaitlistsPage/Loadable'
import { ConsignmentsPage } from './pages/ConsignmentsPage/Loadable'
import { RemarksPage } from './pages/RemarksPage/Loadable'
import { LeadsPage } from './pages/LeadsPage/Loadable'
import { LeadPage } from './pages/LeadPage/Loadable'
import { RestrictedPage } from './pages/RestrictedPage'
import { AppointmentsPage } from './pages/AppointmentsPage'
import { AppraisalsPage } from './pages/AppraisalsPage'
import { AppraisalInspectionPage } from './pages/AppraisalInspectionPage/Loadable'
import { VehicleMarketValuesPage } from './pages/VehicleMarketValuesPage'
import { OffersPage } from './pages/OffersPage/Loadable'
import { BookingLeadsPage } from './pages/BookingLeadsPage/Loadable'
import { InquiriesPage } from './pages/InquiriesPage/Loadable'
import { QuotationsPage } from './pages/QuotationsPage/Loadable'
import { SuppliersPage } from './pages/SuppliersPage/Loadable'
import { NotFoundPage } from './components/NotFoundPage/Loadable'

// Components
import { Header } from './components/Header'
import { Sidebar } from './components/Sidebar'
import { Footer } from './components/Footer'
// Utils
import { Auth } from '../utils/auth'
// External
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export function App() {
  const [isFullPageLayout, setFullPageLayout] = React.useState(true)

  React.useEffect(() => {
    onRouteChanged()
  }, [])

  React.useEffect(() => {
    const sidebar = document.querySelector('#sidebar')
    if (sidebar) {
      sidebar.classList.remove('active')
    }
  }, [window.location.pathname])

  const onRouteChanged = () => {
    window.scrollTo(0, 0)
    const fullPageLayoutRoutes = ['/login']
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (window.location.pathname === fullPageLayoutRoutes[i]) {
        setFullPageLayout(true)
        break
      } else {
        setFullPageLayout(false)
      }
    }
  }

  // private route means only logged user can access
  const RequireAuth = ({
    children,
    roles,
  }: {
    children: JSX.Element
    roles: any
  }) => {
    const isLoggedIn = Auth.isLogged()
    const userRoles = Auth.role() || []
    const location = useLocation()
    const canAccess =
      roles && roles.some((item: string) => userRoles.includes(item))
    if (!isLoggedIn) {
      return <Navigate to="/login" state={{ from: location }} replace />
    } else if (isLoggedIn && !canAccess) {
      return <Navigate to="/restricted" />
    } else {
      return children
    }
  }

  let navbarComponent = !isFullPageLayout ? <Header /> : ''
  let sidebarComponent = !isFullPageLayout ? <Sidebar /> : ''
  let footerComponent = !isFullPageLayout ? <Footer /> : ''
  let bodyClasses = !isFullPageLayout
    ? 'container-fluid page-body-wrapper'
    : 'container-fluid page-body-wrapper full-page-wrapper'

  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - CarmaxPH Admin v3" defaultTitle="">
        <meta name="description" content="" />
      </Helmet>
      <div className="container-scroller">
        {/* header */}
        {navbarComponent}
        <div className={bodyClasses}>
          {/* sidebar */}
          {sidebarComponent}
          <div className="main-panel">
            <div className="content-wrapper">
              <Routes>
                <Route
                  path="/"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'accounting',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <HomePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/feedbacks"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'accounting',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <FeedbacksPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/vehicles"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'accounting',
                        'agent',
                        'inventory',
                        'acquisition',
                        'manager',
                      ]}
                    >
                      <VehiclesPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/vehicles/:id"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'acquisition',
                        'manager',
                      ]}
                    >
                      <VehiclePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/collections"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'accounting',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <CollectionsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/remarks"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <RemarksPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/vehicles/inspection/:id"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'acquisition',
                        'manager',
                      ]}
                    >
                      <VehicleInspectionPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/bookings"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <BookingsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/generalbookings"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <GeneralBookingsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/quotations"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <QuotationsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/appointments"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <AppointmentsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/bookings/leads"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <BookingLeadsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/financings"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <FinancingsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/financings/:id"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <FinancingPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/financings/applications"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <FinancingApplicationPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/reservations"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <ReservationsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/offers"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <OffersPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <RequireAuth roles={['super_admin']}>
                      <UsersPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/banners"
                  element={
                    <RequireAuth roles={['super_admin', 'admin', 'manager']}>
                      <BannersPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/articles"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <ArticlesPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/waitlists"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <WaitlistsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/appraisals"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'acquisition',
                        'manager',
                        'inventory',
                      ]}
                    >
                      <AppraisalsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/appraisals/inspection/:id"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'acquisition',
                        'manager',
                        'inventory',
                      ]}
                    >
                      <AppraisalInspectionPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/vehicles-market-value"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'acquisition',
                        'manager',
                        'inventory',
                      ]}
                    >
                      <VehicleMarketValuesPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/leads"
                  element={
                    <RequireAuth
                      roles={['super_admin', 'admin', 'inventory', 'manager']}
                    >
                      <LeadsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/leads/:id"
                  element={
                    <RequireAuth
                      roles={['super_admin', 'admin', 'inventory', 'manager']}
                    >
                      <LeadPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/inquiries"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'admin',
                        'agent',
                        'inventory',
                        'manager',
                      ]}
                    >
                      <InquiriesPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/suppliers"
                  element={
                    <RequireAuth
                      roles={[
                        'super_admin',
                        'acquisition',
                        'manager',
                        'inventory',
                      ]}
                    >
                      <SuppliersPage />
                    </RequireAuth>
                  }
                />
                <Route path="/restricted" element={<RestrictedPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </div>
            {/* footer */}
            {footerComponent}
            <ToastContainer // toast container
              position="top-right"
              autoClose={6000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
          <GlobalStyle />
        </div>
      </div>
    </BrowserRouter>
  )
}
