/**
 *
 * Asynchronously loads the component for LeadsPage
 *
 */

import { lazyLoad } from 'utils/loadable'

export const LeadsPage = lazyLoad(
  () => import('./index'),
  module => module.LeadsPage,
)
