/**
 *
 * Appraisal
 *
 */
import * as React from 'react'
// React bootstrap
import { Modal, Button } from 'react-bootstrap'
// External
import { useForm, Controller } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import Select from 'react-select'
import { AiFillCloseCircle } from 'react-icons/ai'
import { BsChevronRight } from 'react-icons/bs'

const thumbsContainer: any = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: 16,
}

const thumb: any = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner: any = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img: any = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

const years = [
  '2024',
  '2023',
  '2022',
  '2021',
  '2020',
  '2019',
  '2018',
  '2017',
  '2016',
  '2015',
  '2014',
  '2013',
  '2012',
  '2011',
  '2010',
  '2009',
  '2008',
  '2007',
  '2006',
  '2005',
  '2004',
  '2003',
  '2002',
  '2001',
  '2000',
  '1999',
  '1998',
  '1997',
  '1996',
  '1995',
]

const styles = {
  container: base => ({
    ...base,
    flex: 1,
  }),
  control: styles => ({ ...styles, backgroundColor: 'white', borderWidth: 0 }),
  input: styles => ({ ...styles, height: '42px' }),
}

const baseStyle = {
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}
const activeStyle = {
  borderColor: '#2196f3',
}
const acceptStyle = {
  borderColor: '#00e676',
}
const rejectStyle = {
  borderColor: '#ff1744',
}

interface Props {
  visible: boolean
  handleClose: () => void
  appraisers: any
  onSubmit: (data: any) => void
}

export function Appraisal(props: Props) {
  const { register, handleSubmit, reset, watch, setValue, control } = useForm()

  const [loading, setLoading] = React.useState<boolean>(false)
  const [acceptedFiles, setAcceptedFiles] = React.useState<any>([])
  const [files, setFiles] = React.useState<any>([])
  const [makes, setMakes] = React.useState<any>([])
  const [models, setModels] = React.useState<any>([])
  const [manualMakeInput, setManualMakeInput] = React.useState<boolean>(false)
  const [manualModelInput, setManualModelInput] = React.useState<boolean>(false)

  const onDrop = React.useCallback((acceptedFiles: any) => {
    setAcceptedFiles(acceptedFiles)
    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    )
  }, [])

  React.useEffect(() => {
    fetchMakes()
  }, [])

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      handleSelect(value, name)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const fetchMakes = async () => {
    try {
      const makesList = await axios.get('https://appraisal.carmax.com.ph/makes')
      let options: any = []
      if (makesList?.data) {
        makesList?.data?.map((make: any) => {
          options.push({ value: make, label: make })
        })
        options.push({ value: 'manual_make', label: '[Input vehicle make]' })
      }
      setMakes(options)
    } catch (error) {}
  }

  const handleSelect = async (value: any, name: any) => {
    try {
      if (name === 'make') {
        if (value?.[name]?.value === 'manual_make') {
          setManualMakeInput(true)
          setValue('make', '')
        } else {
          const modelsList = await axios.get(
            'https://appraisal.carmax.com.ph/models?make=' + value?.make?.value,
          )
          let options: any = []
          if (modelsList?.data) {
            modelsList?.data?.map((model: any) => {
              options.push({ value: model, label: model })
            })
            options.push({ value: 'manual_model', label: '[Input make model]' })
          }
          setModels(options)
        }
      }
      if (name === 'model' && value?.[name]?.value === 'manual_model') {
        setManualModelInput(true)
        setValue('model', '')
      }
    } catch (error) {}
  }

  const handleClose = () => {
    reset()
    props.handleClose()
  }

  const onSubmit = async (data: any) => {
    // get predicted appraised value
    try {
      setLoading(true)
      const params = {
        make: data?.make?.value || data?.make,
        model: data?.model?.value || data?.model,
        year: data?.year,
        transmission: data?.transmission,
        fuel_type: data?.fuel_type,
        mileage: data?.mileage,
        asking_price: data?.asking_price,
      }
      const queryString = Object.keys(params)
        .map(key => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        })
        .join('&')
      const response = await axios.get(
        'https://appraisal.carmax.com.ph/predict?' + queryString,
      )

      if (response?.data) {
        data.estimated_value = response?.data?.predicted_value
        submitAppraisal(data)
      }
    } catch (error) {
      submitAppraisal(data)
    }
  }

  const submitAppraisal = data => {
    const formData = new FormData()
    Object.keys(data).forEach(key => {
      if (key === 'make' || key === 'model') {
        formData.append(key, data[key]?.value || data[key])
      } else {
        formData.append(key, data[key])
      }
    })
    formData.append('status', 'For Approval')
    formData.append('origin', 'cms')
    if (acceptedFiles.length) {
      Object.keys(acceptedFiles).forEach(key => {
        formData.append('files[]', acceptedFiles[key])
      })
    }
    props.onSubmit(formData)
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
    maxFiles: 3,
  })

  const thumbs = files.map((file: any) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          alt="carmax.png"
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
      </div>
    </div>
  ))

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )

  return (
    <Modal show={props.visible} size="lg" onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Add appraisal</Modal.Title>
        <p onClick={handleClose}>
          <AiFillCloseCircle size={25} />
        </p>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>Year</label>
                <select
                  className="form-control form-control-lg"
                  {...register('year', { required: true })}
                  onChange={e => handleSelect('year', e.target.value)}
                >
                  <option value="">--</option>
                  {years.map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Make</label>
                {!manualMakeInput ? (
                  <Controller
                    control={control}
                    name="make"
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        options={makes}
                        onChange={onChange}
                        isSearchable={true}
                        styles={styles}
                      />
                    )}
                  />
                ) : (
                  <input
                    className="form-control form-control-lg"
                    {...register('make', { required: true })}
                  />
                )}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Model</label>
                {!manualModelInput ? (
                  <Controller
                    control={control}
                    name="model"
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        options={models}
                        onChange={onChange}
                        isSearchable={true}
                        styles={styles}
                      />
                    )}
                  />
                ) : (
                  <input
                    className="form-control form-control-lg"
                    {...register('model', { required: true })}
                  />
                )}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Variant</label>
                <input
                  className="form-control form-control-lg"
                  {...register('variant', { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>Vehicle type *</label>
                <select
                  className="form-control form-control-lg"
                  {...register('vehicle_type', { required: true })}
                >
                  <option value="" />
                  <option value="Sedan">Sedan</option>
                  <option value="Compact">Compact</option>
                  <option value="SUV">SUV</option>
                  <option value="Midsize SUV">Midsize SUV</option>
                  <option value="Van">Van</option>
                  <option value="Pick Up">Pick Up</option>
                  <option value="Motorcycle">Motorcycle</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Mileage</label>
                <select
                  className="form-control form-control-lg"
                  {...register('mileage', { required: true })}
                >
                  <option value="">--</option>
                  <option value="0-10,000">0-10,000</option>
                  <option value="10,001-20,000">10,001-20,000</option>
                  <option value="20,001-30,000">20,001-30,000</option>
                  <option value="30,001-40,000">30,001-40,000</option>
                  <option value="40,001-50,000">40,001-50,000</option>
                  <option value="50,001-60,000">50,001-60,000</option>
                  <option value="60,001-70,000">60,001-70,000</option>
                  <option value="70,001-80,000">70,001-80,000</option>
                  <option value="80,001-90,000">80,001-90,000</option>
                  <option value="90,001-100,000">90,001-100,000</option>
                  <option value="100,000"> more than 100,000</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Fuel type</label>
                <select
                  className="form-control form-control-lg"
                  {...register('fuel_type', { required: true })}
                >
                  <option value="" />
                  <option value="Gas">Gas</option>
                  <option value="Diesel">Diesel</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Transmission</label>
                <select
                  className="form-control form-control-lg"
                  {...register('transmission', { required: true })}
                >
                  <option value="" />
                  <option value="Manual">Manual</option>
                  <option value="Automatic">Automatic</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>Engine Size</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  {...register('engine_size', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Plate #</label>
                <input
                  className="form-control form-control-lg"
                  {...register('plate_no', {
                    required: true,
                    onChange: e => {
                      const inputValue = e.target.value
                      const transformedValue = inputValue
                        .replace(/[^a-zA-Z0-9]/g, '')
                        .toUpperCase()
                      e.target.value = transformedValue
                      return transformedValue
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Asking price</label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  {...register('asking_price', { required: false })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Source</label>
                <select
                  className="form-control form-control-lg"
                  {...register('source', { required: false })}
                >
                  <option value="" />
                  <option value="FB inquiries">FB inquiries</option>
                  <option value="Marketplace">Marketplace</option>
                  <option value="Other platforms">Other platforms</option>
                  <option value="Referral ">Referral </option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>Inital appraised value</label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  {...register('initial_appraised_value', { required: false })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Appraiser</label>
                <select
                  className="form-control form-control-lg"
                  {...register('appraiser_id', { required: true })}
                >
                  <option value="" />
                  {props.appraisers.map((appraiser: any) => (
                    <option key={appraiser.id} value={appraiser.id}>
                      {appraiser.firstname + ' ' + appraiser.lastname}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Remarks</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  {...register('remarks', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Modifications</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  {...register('modifications', { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label>Issues</label>
                <input
                  className="form-control form-control-lg"
                  {...register('issues', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="upload-container">
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p className="text-center mb-0">
                    Drag 'n' drop some files here, or click to select files
                  </p>
                  <small>(Only *.jpeg and *.png images will be accepted)</small>
                </div>
              </div>
              <aside style={thumbsContainer}>{thumbs}</aside>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>Firstname</label>
                <input
                  className="form-control form-control-lg"
                  {...register('firstname', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Lastname</label>
                <input
                  className="form-control form-control-lg"
                  {...register('lastname', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  {...register('email', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Phone</label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  {...register('phone', {
                    required: true,
                    minLength: 11,
                    maxLength: 12,
                  })}
                  onInput={e => {
                    if (e.currentTarget.value.length > 12) {
                      e.currentTarget.value = e.currentTarget.value.slice(0, 12)
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>I am interested to</label>
                <select
                  className="form-control form-control-lg"
                  {...register('intention', { required: true })}
                >
                  <option value="" />
                  <option value="buy">Buy</option>
                  <option value="sell">Sell</option>
                  <option value="trade-in">Trade-In</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-2 mb-3">
            <div className="col-lg-12">
              <div className="d-flex justify-content-end">
                {loading ? (
                  <Button type="button" variant="primary" disabled={loading}>
                    Loading......
                  </Button>
                ) : (
                  <Button type="submit" variant="primary" disabled={loading}>
                    Inspection report <BsChevronRight size={25} />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
