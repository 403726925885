import jwtDecode from 'jwt-decode'
import { removeLocalStorage, getLocalStorage } from './tokenStorage'

interface Token {
  exp: number
  role: any
}

interface User {
  user_id: number
  firstname?: string
  lastname?: string
  email: string
  dealership?: string
  exp: number
  iat: number
  iss: string
  jti: string
  nbf: number
  prv: string
  role?: [string]
  sub: string
}

export const Auth = {
  isLogged() {
    const storedToken = getLocalStorage() && getLocalStorage().token
    if (storedToken) {
      const token: Token = jwtDecode(storedToken)
      if (typeof token === 'object' && token !== null) {
        if (token.exp < Date.now() / 1000) {
          removeLocalStorage()
          return false
        }
      }
      return true
    }
    return false
  },
  role() {
    const storedToken = getLocalStorage() && getLocalStorage().token
    if (storedToken) {
      const token: Token = jwtDecode(storedToken)
      return token.role
    }
    return null
  },
  user() {
    const storedToken = getLocalStorage() && getLocalStorage().token
    if (storedToken) {
      const token: User = jwtDecode(storedToken)
      return token
    }
    return null
  },
}
